//
// Col form label
//

.dark-mode {
  background-color: #12121B;
}

.dark-mode .form-section {
  background-color: #121212;
}

.dark-mode .fieldset-group {
  border: 1px solid #636B7B !important;
}

.dark-mode .fieldset-legend {
  color: #E9EAED;
}

.dark-mode .form-control {
  color: #E9EAED;
}

.dark-mode .select2-selection__rendered {
  color: #E9EAED;
}

.dark-mode .nav-line-tabs .nav-item .nav-link {
  color: #9696F1;
}

.dark-mode .nav-line-tabs .nav-item .nav-link.active, .dark-mode .nav-line-tabs .nav-item.show .nav-link, .dark-mode .nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  border-bottom-color: #9696F1;
}

.dark-mode .nav-line-tabs {
  border-bottom-color:  #232C3D;
}

.dark-mode .fieldset-input {
  [class$="singleValue"] {
    color: #E9EAED;
  }
}

.dark-mode .btn.btn-wl-custom-primary-collor { 
  background-color: #6666DE;
}

.dark-mode .btn.btn-wl-custom-primary-collor i[class^=fa-],
.dark-mode .btn.btn-wl-custom-primary-collor i[class*=" fa-"]{
  background-color: #6666DE;
}


.dark-mode .MuiFormLabel-root, .dark-mode .MuiInputLabel-root, .dark-mode .MuiInputBase-input {
  color: #E9EAED;
}

.dark-mode .MuiInputBase-root fieldset {
  border-color: #636B7B;
}

.dark-mode .custom-checkbox-input, .dark-mode .form-check-label, .dark-mode .custom-radio-input
{
  color: #E9EAED;
}

.dark-mode .form-switch .form-check-input
{
  background-color: #FFF;
}

.dark-mode .form-switch .form-check-input:checked
{
  background-color: var(--wl-btn-background-primary-collor);
}