//
// Col form label
//

.fieldset-group {
  border-radius: 4px;
  padding-left: 0.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.fieldset-legend {
  color: #909191;
  float: none !important;
  width: auto !important;
  font-size: 0.8rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-bottom: 0.1rem;
  
}

.fieldset-group .fieldset-input {
  border: none !important;
  padding-top: 0rem;
  padding-bottom: 0.4rem;
  padding-left: 0.2rem;
  background-color: transparent;
}

.form-check-label {
  color: black;
}

.MuiDateCalendar-root {
  width: 250px !important;
}

.MuiDateCalendar-root, .MuiTimeClock-root {
  width: 250px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24 {
  min-width: 250px !important;
}